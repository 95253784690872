import { render, staticRenderFns } from "./OrganismImageSliderV2.vue?vue&type=template&id=fba54c2c&"
import script from "./OrganismImageSliderV2.vue?vue&type=script&lang=js&"
export * from "./OrganismImageSliderV2.vue?vue&type=script&lang=js&"
import style0 from "./OrganismImageSliderV2.vue?vue&type=style&index=0&id=fba54c2c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/OrganismImageSliderV2.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Forganisms%2Fcommon%2FOrganismImageSliderV2.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesCommonMoleculeImageSliderWithThumbnails: require('/app/components/molecules/common/MoleculeImageSliderWithThumbnails.vue').default,MoleculesCommonMoleculeVideoSliderWithThumbnails: require('/app/components/molecules/common/MoleculeVideoSliderWithThumbnails.vue').default})
